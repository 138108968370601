.carrito-estado{
  display: flex;
  width: 100%;
  height: 200px;

  align-items: center;
}

.carrito-logo3x{
margin-left: 10%;
}

.carrito-logo3x img{
width: 80%;
height: 80%;
}

.carrito-estado-texto{
  margin-left: 1%;
  color: ##1c1c1b;
}

.carrito-estado-texto h3{
  font-weight: bolder;
  font-size: 24px;
  margin-bottom: 15px;
}

.carrito-estado-texto h4{
  font-weight: lighter;
  font-size: 20px;
}

.carrito-estado-texto h5{
  font-weight: lighter;
  font-size: 16px;
}

.linea-separador{
  width: 80%;
  height: 1px;
  border: solid 1px #979797;
  margin: auto;
}
