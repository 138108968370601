/* articulos y resumen del pedido */


.contenedor-articulos-resumen-pedido{
  display: flex;
  width: 100%;
  height: inherit;
}

.contenedor-articulos{
  width: 800px;
  margin-left: 10%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.titulo-articulos h3{
  color: #1c1c1b;
  font-size: 26px;
  font-weight: bolder;
}

.campo-articulo{
margin-top: 20px;
display: flex;
}

.img-articulo{
  width: 148px;
  height: 149px;
  background-image: linear-gradient(-30deg, #f1f1f1, #e3f9ff);
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #8a8a8a;
}

.img-articulo img{
  width: 148px;
  height: 90px;
}

.eliminar-articulo{
  margin-top: 10px;
}

/* .eliminar-articulo h3{
  margin-top: 30px;
  color: #8a8a8a;
  font-size: 18px;
  font-weight: 100;
  tex
} */

.sin-estilo{
  text-decoration: underline;
  border: 0;
  font-size: 14px;
  font-weight: 100;
  background-color: inherit;
  color: #8a8a8a;
}

.texto-descripcion{
  width: 75%;
  margin-left: 30px;
}

.nombre-producto{
  display: flex;
}
.nombre-producto h4{
  width: 150px;
  height: 21px;
  font-size: 18px;
  font-weight: bold;
  color: #1c1c1b;
  display: flex;
  margin-right: 50px;
}

.cantidad{
  display: flex;
  width: 100px;
  height: 22px;
  border-radius: solid 1px #8a8a8a;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.number{
  width: 40px;
  height: 20px;
}

.precio h4{
  justify-content: flex-end;
  font-size: 18px;
  font-weight: bold;
  color: #e15600;
}

.texto-descripcion h5{
  margin-top: 20px;
  font-size: 15px;
  font-weight: lighter;
  color: #8a8a8a;
}

.texto-descripcion p{
  margin-top: 5px;
  font-size: 14px;
  font-weight: 300;
  color: #8a8a8a;
}

.boton3 {
  display: flex;
  width: 160px;
  height: 55px;
  margin-top: 50px;
  border-radius: 4px;
  background-color: #e15600;
  justify-content: center;
  align-items: center;
  text-decoration-color: #fff;
  position: relative;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.boton3:hover {
  background-color: #1C1C1B;
}

/* resumen del pedido */

.contenedor-resumen-pedido{
  width: 360px;
  height: inherit;
  margin-right: 10%;
  margin-top: 40px;
  border: solid 1px #979797;
  display: flex;
  flex-direction: column;
}

.resumen-titulo{
  margin: 0 auto;
  width: 360px;
  height: 82px;
  border: solid 1px #979797;
  background-color: #d8d8d8;
  display: flex;
  align-items: center;
}

.resumen-titulo h2{
  margin-left: 20px;
  font-size: 24px;
  font-weight: 900;
  color: #1c1c1b;
}

.listado-productos{
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.titulo-productos h3{
  font-size: 18px;
  font-weight: 500;
  color: #8a8a8a;
}

.cantidad-productos{
  margin-top: 10px;
  display: flex;
}

.cantidad-productos h4{
  width: 170px;
  font-size: 14px;
  font-weight: 500;
  color: #8a8a8a;
}

.precio-producto{
  display: flex;
  text-align: right;
}

.precio-producto h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.linea{
  border: solid 1px #8a8a8a;
  display: flex;
  margin-right: 20px;
  margin-top: 10px;
}

.subtotal{
  display: flex;
  margin-top: 20px;
}

.subtotal h4{
  width: 170px;
  font-size: 14px;
  font-weight: 900;
  color: #8a8a8a;
}

.precio-subtotal{
  display: flex;
  text-align: right;
}

.precio-subtotal h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.descuento{
  display: flex;
  margin-top: 10px;
}

.descuento h4{
  width: 170px;
  font-size: 14px;
  font-weight: 900;
  color: #8a8a8a;
}

.precio-descuento{
  display: flex;
  text-align: right;
}

.precio-descuento h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.total-producto{
  display: flex;
  margin-top: 20px;
}

.total-producto h4{
  width: 170px;
  font-size: 14px;
  font-weight: 900;
  color: #8a8a8a;
}

.precio-producto{
  display: flex;
  text-align: right;
}

.precio-producto h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.gastos-envio{
  display: flex;
  margin-top: 20px;
}

.gastos-envio h4{
  width: 170px;
  font-size: 14px;
  font-weight: 900;
  color: #8a8a8a;
}

.precio-envio{
  display: flex;
  text-align: right;
}

.precio-envio h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.total{
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
}

.total h3{
  width: 170px;
  font-size: 18px;
  font-weight: 900;
  color: #1c1c1b;
}

.precio-total{
  display: flex;
  text-align: right;
}

.precio-total h3{
  margin-right: 20px;
  font-size: 18px;
  font-weight: 900;
  color: #e15600;
}
