/* articulos y resumen del pedido */


.contenedor-datos-resumen-pedido{
  display: flex;
  width: 100%;
  height: inherit;
}

.contenedor-datos{
  width: 800px;
  margin-left: 10%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.titulo-datos h3{
  color: #1c1c1b;
  font-size: 26px;
  font-weight: bolder;
}

.campo-datos{
margin-top: 20px;
display: flex;
flex-direction: column;
}

.nombre-apellido{
  display: flex;
}

.nombre-datos{
  display: flex;
  align-items: center;
  width: 345px;
  height: 46px;
  padding-left: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}


.apellido-datos{
  display: flex;
  align-items: center;
  width: 345px;
  height: 46px;
  padding-left: 15px;
  margin-left: 10px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.direccion-datos{
  display: flex;
  align-items: center;
  width: 390px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.edificio-datos{
  display: flex;
  align-items: center;
  width: 702px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.departamento-datos{
  display: flex;
  align-items: center;
  width: 298px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.ciudad-datos{
  display: flex;
  align-items: center;
  width: 298px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.codigo-datos{
  display: flex;
  align-items: center;
  width: 298px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.telefono-datos{
  display: flex;
  align-items: center;
  width: 298px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.telefono p{
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.titulo-datos-facturacion h3{
  margin-top: 20px;
  color: #1c1c1b;
  font-size: 26px;
  font-weight: bolder;
}

.correo-datos{
  display: flex;
  align-items: center;
  width: 702px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.documento-datos{
  display: flex;
  align-items: center;
  width: 298px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.documento-numero-datos{
  display: flex;
  align-items: center;
  width: 298px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.check{
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}
.first_checkbox{
  width: 46px;
  height: 46px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
}
.checkmark{
  width: 46px;
  height: 46px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
}


.boton3{
  display: flex;
  width: 160px;
  height: 55px;
  margin-top: 50px;
  border-radius: 4px;
  background-color: #e15600;
  justify-content: center;
  align-items: center;
  text-decoration-color: #fff;
  position: relative;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.boton3:hover {
  background-color: #1C1C1B;
}

/* resumen del pedido */

.contenedor-resumen-pedido{
  width: 360px;
  height: inherit;
  margin-right: 10%;
  margin-top: 40px;
  border: solid 1px #979797;
  display: flex;
  flex-direction: column;
}

.resumen-titulo{
  margin: 0 auto;
  width: 360px;
  height: 82px;
  border: solid 1px #979797;
  background-color: #d8d8d8;
  display: flex;
  align-items: center;
}

.resumen-titulo h2{
  margin-left: 20px;
  font-size: 24px;
  font-weight: 900;
  color: #1c1c1b;
}

.listado-productos{
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.titulo-productos h3{
  font-size: 18px;
  font-weight: 500;
  color: #8a8a8a;
}

.cantidad-productos{
  margin-top: 10px;
  display: flex;
}

.cantidad-productos h4{
  width: 170px;
  font-size: 14px;
  font-weight: 500;
  color: #8a8a8a;
}

.precio-producto{
  display: flex;
  text-align: right;
}

.precio-producto h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.linea{
  border: solid 1px #8a8a8a;
  display: flex;
  margin-right: 20px;
  margin-top: 10px;
}

.subtotal{
  display: flex;
  margin-top: 20px;
}

.subtotal h4{
  width: 170px;
  font-size: 14px;
  font-weight: 900;
  color: #8a8a8a;
}

.precio-subtotal{
  display: flex;
  text-align: right;
}

.precio-subtotal h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.descuento{
  display: flex;
  margin-top: 10px;
}

.descuento h4{
  width: 170px;
  font-size: 14px;
  font-weight: 900;
  color: #8a8a8a;
}

.precio-descuento{
  display: flex;
  text-align: right;
}

.precio-descuento h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.total-producto{
  display: flex;
  margin-top: 20px;
}

.total-producto h4{
  width: 170px;
  font-size: 14px;
  font-weight: 900;
  color: #8a8a8a;
}

.precio-producto{
  display: flex;
  text-align: right;
}

.precio-producto h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.gastos-envio{
  display: flex;
  margin-top: 20px;
}

.gastos-envio h4{
  width: 170px;
  font-size: 14px;
  font-weight: 900;
  color: #8a8a8a;
}

.precio-envio{
  display: flex;
  text-align: right;
}

.precio-envio h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.total{
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
}

.total h3{
  width: 170px;
  font-size: 18px;
  font-weight: 900;
  color: #1c1c1b;
}

.precio-total{
  display: flex;
  text-align: right;
}

.precio-total h3{
  margin-right: 20px;
  font-size: 18px;
  font-weight: 900;
  color: #e15600;
}
