.browser{
  width: 100%;
  height: 140px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.logo-negro{
  padding-right: 15px;
  margin-left: 230px;
}

.buscador-barra {
  display: flex;
  width: 486px;
  height: 43px;
  border-radius: 8px 0 0 8px;
  border: solid 1px #8a8a8a;
  background-color: #f7f7f7;
}

.buscar-productos{
  margin: auto;
  margin-left: 20px;
  color: #8a8a8a;
  font-size: 12px;
  font-weight: lighter;

}

.contenedor-lupa {
  display: flex;
  width: 43px;
  height: 43px;
  border-radius: 0 8px 8px 0;
  background-color: #e15600;
}

.buscar {
  display: flex;
  width: 26px;
  height: 26px;
  margin: auto;
}

.carrito-compras{
  padding-left: 280px;
  display: flex;
}

.carrito-compras a{
  margin: auto;
  margin-left: 10px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  color: #8a8a8a;
}
