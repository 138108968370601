/* articulos y resumen del pedido */


.contenedor-datos-resumen-pedido{
  display: flex;
  width: 100%;
  height: inherit;
}

.contenedor-datos{
  width: 800px;
  margin-left: 10%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.titulo-datos h3{
  color: #1c1c1b;
  font-size: 26px;
  font-weight: bolder;
}

.campo-datos{
margin-top: 20px;
display: flex;
flex-direction: column;
}

.titulo-tarjetas{
  display: flex;
}

.tarjeta-datos{
  display: flex;
  align-items: center;
  width: 345px;
  height: 46px;
  padding-left: 15px;
  margin-top: 20px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.titular-datos{
  display: flex;
  align-items: center;
  width: 702px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}


.caducidad-campo{
  display: flex;
  align-items: center;
  padding-left: 15px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.mes-datos{
  display: flex;
  align-items: center;
  width: 98px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  margin-left: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.ano-datos{
  display: flex;
  align-items: center;
  width: 98px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  margin-left: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.cvv-datos{
  display: flex;
  align-items: center;
  width: 190px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.cuotas-campo{
  display: flex;
  align-items: center;
  padding-left: 15px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.cuotas-datos{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 46px;
  padding-left: 15px;
  margin-top: 15px;
  margin-left: 15px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}

.linea2 {
  width: 702px;
  height: 1px;
  margin-top: 50px;
  margin-bottom: 20px;
  border: solid 1px #979797;
}

.campo-pse{
  display: flex;
  flex-direction: column;
  width: 702px;
}

.titulo-pse{
  display: flex;
}

.titulo-pse img{
  display: flex;
  align-content: flex-end;
}

.campo-pse p{
  margin-top: 20px;
}

.campo-pse p a{
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
  text-decoration: none;
}

.linea3 {
  width: 702px;
  height: 1px;
  margin-top: 50px;
  margin-bottom: 20px;
  border: solid 1px #979797;
}

.campo-efecty{
  display: flex;
  flex-direction: column;
  width: 702px;
}

.titulo-efecty{
  display: flex;
}

.titulo-efecty img{
  display: flex;
  align-content: flex-end;
}

.campo-efecty p{
  margin-top: 20px;
}

.campo-efecty p a{
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
  text-decoration: none;
}

.linea4 {
  width: 702px;
  height: 1px;
  margin-top: 50px;
  margin-bottom: 20px;
  border: solid 1px #979797;
}

.campo-baloto{
  display: flex;
  flex-direction: column;
  width: 702px;
}

.titulo-baloto{
  display: flex;
}

.titulo-baloto img{
  display: flex;
  align-content: flex-end;
}
.campo-baloto p{
  margin-top: 20px;
}

.campo-baloto p a{
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
  text-decoration: none;
}

.boton3{
  display: flex;
  width: 160px;
  height: 55px;
  margin-top: 50px;
  border-radius: 4px;
  background-color: #e15600;
  justify-content: center;
  align-items: center;
  text-decoration-color: #fff;
  position: relative;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.boton3:hover {
  background-color: #1C1C1B;
}

/* resumen del pedido */

.contenedor-resumen-pedido{
  width: 360px;
  height: inherit;
  margin-right: 10%;
  margin-top: 40px;
  border: solid 1px #979797;
  display: flex;
  flex-direction: column;
}

.resumen-titulo{
  margin: 0 auto;
  width: 360px;
  height: 82px;
  border: solid 1px #979797;
  background-color: #d8d8d8;
  display: flex;
  align-items: center;
}

.resumen-titulo h2{
  margin-left: 20px;
  font-size: 24px;
  font-weight: 900;
  color: #1c1c1b;
}

.listado-productos{
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.titulo-productos h3{
  font-size: 18px;
  font-weight: 500;
  color: #8a8a8a;
}

.cantidad-productos{
  margin-top: 10px;
  display: flex;
}

.cantidad-productos h4{
  width: 170px;
  font-size: 14px;
  font-weight: 500;
  color: #8a8a8a;
}

.precio-producto{
  display: flex;
  text-align: right;
}

.precio-producto h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.linea{
  border: solid 1px #8a8a8a;
  display: flex;
  margin-right: 20px;
  margin-top: 10px;
}

.subtotal{
  display: flex;
  margin-top: 20px;
}

.subtotal h4{
  width: 170px;
  font-size: 14px;
  font-weight: 900;
  color: #8a8a8a;
}

.precio-subtotal{
  display: flex;
  text-align: right;
}

.precio-subtotal h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.descuento{
  display: flex;
  margin-top: 10px;
}

.descuento h4{
  width: 170px;
  font-size: 14px;
  font-weight: 900;
  color: #8a8a8a;
}

.precio-descuento{
  display: flex;
  text-align: right;
}

.precio-descuento h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.total-producto{
  display: flex;
  margin-top: 20px;
}

.total-producto h4{
  width: 170px;
  font-size: 14px;
  font-weight: 900;
  color: #8a8a8a;
}

.precio-producto{
  display: flex;
  text-align: right;
}

.precio-producto h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.gastos-envio{
  display: flex;
  margin-top: 20px;
}

.gastos-envio h4{
  width: 170px;
  font-size: 14px;
  font-weight: 900;
  color: #8a8a8a;
}

.precio-envio{
  display: flex;
  text-align: right;
}

.precio-envio h4{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;
  color: #e15600;
}

.total{
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
}

.total h3{
  width: 170px;
  font-size: 18px;
  font-weight: 900;
  color: #1c1c1b;
}

.precio-total{
  display: flex;
  text-align: right;
}

.precio-total h3{
  margin-right: 20px;
  font-size: 18px;
  font-weight: 900;
  color: #e15600;
}
