
footer{
  width: 100%;
  height: 380px;
  background-color: #000000;
  display: flex;
  align-items: center;
}

.logo-redondo{
  margin-left: 7%;
}

.logo-redondo img{
  width: 70%;
}

.texto-direccion{
  margin-left: 1%;;
  font-size: 14px;
  font-weight: 100;
  display: flex;
  flex-direction: column;
}

.texto-direccion h3{
  color: #e15600;
}

.texto-direccion h4{
  margin-top: 10px;
  font-size: 14px;
  font-weight: bolder;
  color: #fff;
}

.listas-categorias{
  display: flex;
  flex-direction: column;
  margin-left: 16%;
}

.listas-categorias h2{
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  margin-bottom: 20px;
}

.listas-categorias a{
  margin-top: 15px;
  color: #f7e217;
  text-decoration: none;
  font-weight: lighter;
  font-size: 14px;
}

.listas-cliente{
  display: flex;
  flex-direction: column;
  margin-left: 7%;
}

.listas-cliente h2{
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  margin-bottom: 20px;
}

.listas-cliente a{
  margin-top: 15px;
  color: #f7e217;
  text-decoration: none;
  font-weight: lighter;
  font-size: 14px;
}

.powered{
  width: 100%;
  height: 50px;
  background-color: #d8d8d8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #1c1c1b;
}

.powered h5{
  font-size: 12px;
}

.powered h4{
  margin-top: 5px;
  font-size: 14px;
}

.powered a{
  text-decoration: none;
  color: #e15600;
}
