header.account {
  width: 100%;
  height: 100px;
  background-color: #e15600;
  display: flex;
  align-items: center;
}

.icono-home{
  height: 40%;
  margin-left: 50px;
}

.lema-logo{
  flex: 1;
  font-size: 14px;
  font-weight: lighter;
  color: #fff;
  padding-left: 100px;
}
