
/* slider */

.contenedor-slider{
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 470px;
  background-image: linear-gradient(-30deg, #f1f1f1, #e3f9ff);
}

.images-slide1{
  text-align: center;
}

.images-slide1 img{
  margin-left: 0;
  width: 80%;
  align-items: center;
}

.contenedor-info-slide1{
  display: flex;
  flex: 0.5;
  flex-direction: column;
  align-items: flex-end;
  align-self: center;
  justify-content: center;
  margin-right: 9%;
}

.texto-slide1{
  text-align: right;
}

.texto-slide1 h4{
  color: #1C1C1B;
  font-size: 36px;
  font-weight: 100;
}

.texto-slide1 h1{
  color: #e15600;
  font-size: 66px;
  font-weight: 500;
}

.boton{
  display: flex;
  width: 160px;
  height: 55px;
  margin-top: 10px;
  border-radius: 4px;
  background-color: #e15600;
  justify-content: center;
  align-items: center;
  text-decoration-color: #fff;
  position: relative;
}

.boton:hover{
  background-color: #1C1C1B;
}

.boton a{
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}


/* promociones */

.contenedor-promos{
  display: flex;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.contenedor-promos a{
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.promo1{
  display: inline-flex;
  margin: auto;
  width: 268px;
  height: 107px;
  background-image: linear-gradient(-30deg, #e3f9ff, #f1f1f1);
  align-items: center;
  justify-content: center;
}

.promo1 img{
  width: 80%;
  height: 80%;
  object-fit: contain;
}


/* contenedor descuento semanal y mas vendidos */


.contenedor-general-descuento-semanal-mas-Vendidos{
  display: flex;
}

/* descuento semanal */

.contenedor-descuento-semanal {
  width: 320px;
  height: 540px;
  background-color: rgba(237, 237, 237, 0.420);
  display: flex;
  flex-direction: column;
  margin-left: 7%;
}

.descuento-semanal{
  margin-left: 10%;
  margin-top: 10%;
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  color: #1C1C1B;
}

.contenedor-descuento-semanal-info{
  width: 85%;
  height: 85%;
  margin: 0 auto;
  margin-top: 15px;
  padding-top: 30px;
  background-image: linear-gradient(-30deg, #e3f9ff, #f1f1f1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contenedor-descuento-semanal-info img{
  width: 100%;
  margin-top: 5%;
}

img.logo{
  width: 13%;
}

.contenedor-descuento-semanal-info h5{
  font-weight: 100;
  font-size: 22px;
  color: #1c1c1b;
  padding-top: 2%;
}

.contenedor-descuento-semanal-info h6{
  font-weight: 400;
  font-size: 18px;
  color: #1c1c1b;
}

.contenedor-descuento-semanal-info h4{
  font-weight: 600;
  font-size: 28px;
  color: #e15600;
  padding-top: 3%;
}

.boton2{
  display: flex;
  margin-top: 6%;
  width: 160px;
  height: 55px;
  border-radius: 4px;
  background-color: #e15600;
  align-items: center;
}

.boton2 a{
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  margin: 0 auto;
}

/* más vendidos */


.contenedor-mas-vendidos{
  width: 65%;
  height: 540px;
  background-color: rgba(237, 237, 237, 0.420);
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  margin-right: 7%;
  justify-content: center;
}

.texto-mas-vendidos{
  margin-left: 4%;
  margin-top: 3%;
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  color: #1C1C1B;
  text-decoration: none;
}

.mas-vendidos{
  width: 95.5%;
  height: 90%;
  margin: 19px;
  margin-right: 10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.listado-mas-vendidos{
  width: 192px;
  height: 220px;
  margin-right: 2%;
  margin-bottom: 2%;
  background-image: linear-gradient(-30deg, #e3f9ff, #f1f1f1);
  display: flex;
  justify-content: center;
}

.listado-mas-vendidos a{
  text-decoration: none;
}

.listado-mas-vendidos img{
  width: 100%;
  margin-top: 20%;
}

.listado-mas-vendidos h5{
  font-weight: 100;
  font-size: 14px;
  color: #1c1c1b;
  margin-top: 15%;
  text-align: center;
}

.listado-mas-vendidos h4{
  font-weight: bolder;
  font-size: 20px;
  color: #e15600;
  margin-top: 2%;
  text-align: center;
}


/* promociones-2 */

.contenedor-promos-2{
  display: flex;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.contenedor-promos-2 a{
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.promo-2{
  display: inline-flex;
  margin: auto;
  margin-top: 20px;
  width: 376px;
  height: 207px;
  background-image: linear-gradient(-30deg, #e3f9ff, #f1f1f1);
  align-items: center;
  justify-content: center;
}

.promo-2 img{
  width: 80%;
  height: 80%;
  object-fit: contain;
}


/* slider-2 */

.contenedor-slider-2{
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 50px;
  padding: 0;
  width: 100%;
  height: 470px;
  background-image: linear-gradient(-30deg, #f1f1f1, #e3f9ff);
}

.images-slide-2{
  text-align: center;
}

.images-slide-2 img{
  margin-left: 0;
  width: 80%;
  align-items: center;
}

.contenedor-info-slide-2{
  display: flex;
  flex: 0.5;
  flex-direction: column;
  align-items: flex-end;
  align-self: center;
  justify-content: center;
  margin-right: 9%;
}

.texto-slide-2{
  text-align: right;
}

.texto-slide-2 h4{
  color: #1C1C1B;
  font-size: 36px;
  font-weight: 100;
}

.texto-slide-2 h1{
  color: #e15600;
  font-size: 66px;
  font-weight: 500;
}

.boton{
  display: flex;
  width: 160px;
  height: 55px;
  margin-top: 10px;
  border-radius: 4px;
  background-color: #e15600;
  justify-content: center;
  align-items: center;
  text-decoration-color: #fff;
  position: relative;
}

.boton:hover{
  background-color: #1C1C1B;
}

.boton a{
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}


/* promociones-3 */

.contenedor-promos-3{
  display: flex;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.contenedor-promos-3 a{
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.promo-3{
  display: inline-flex;
  margin: auto;
  margin-top: 20px;
  width: 376px;
  height: 207px;
  background-image: linear-gradient(-30deg, #e3f9ff, #f1f1f1);
  align-items: center;
  justify-content: center;
}

.promo-3 img{
  width: 80%;
  height: 80%;
  object-fit: contain;
}


/* newsletters */

.contenedor-suscripcion-noticias{
  width: 100%;
  height: 160px;
  margin-top: 50px;
  background-color: #1c1c1b;
  display: flex;
  align-items: center;
}

.itto-send {
  width: 159px;
  height: 60px;
  margin-left: 7%;
  object-fit: contain;
  justify-content: center;
}

.textos-noticias{
margin-left: 4%;
}

.textos-noticias h3{
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}

.textos-noticias h4{
  font-size: 16px;
  font-weight: 100;
  color: #f7e217;
}

.suscribir-barra {
  display: flex;
  width: 476px;
  height: 43px;
  margin-left: 6%;
  border-radius: 8px 0 0 8px;
  border: solid 1px #8a8a8a;
  background-color: #f7f7f7;
}

.texto-suscribir{
  margin: auto;
  margin-left: 20px;
  color: #8a8a8a;
  font-size: 14px;
  font-weight: lighter;

}

.contenedor-suscribirse {
  display: flex;
  width: 110px;
  height: 43px;
  border-radius: 0 8px 8px 0;
  background-color: #e15600;
  align-items: center;
  justify-content: center;
}

.contenedor-suscribirse h3{
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
