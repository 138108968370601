.contenedor-menu {
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: #ededed;
}

.secciones {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap-reverse;
}

.menu-item {
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  flex: 1;
  border-left: 2px solid #fff;
}

.menu-item-seleccionado {
  background-color: #e15600;
}
.menu-item-seleccionado > a {
  color: #fff !important;
}

.categorias {
  flex: 0.35;
  background-color: #e15600;
  color: #fff;
  position: relative;
  font-size: 18px;
  font-weight: 500;
}

.categorias:hover > .menu_categorias {
  visibility: visible;
}

.menu_categorias {
  background-color: #fff;
  visibility: hidden;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 64px;
  flex-direction: column;
}

.menu_categorias .menu_item_categorias {
  cursor: pointer;
  border-bottom: solid 1px #cfcfcf;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 500;
  color: #303030;
  text-align: center;
  border-top: 2px solid #fff;
  min-height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;
}

.menu_categorias .menu_item_categorias:hover {
  background-color: #ededed;
}


.menu-item > a {
  display: block;
  width: 100%;
  color: #303030;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  justify-content: center;
}

.menu-item:hover > a {
  color: #e15600;
}

.porcentaje-logo-negro {
  width: 27px;
  height: 29px;
  align-items: center;
}
