header.main {
  width: 100%;
  height: 100px;
  background-color: #1c1c1b;
  display: flex;
  align-items: center;
}

header.main nav {
  display: flex;
  text-align: center;
  margin-right: 50px;
  align-items: center;
  justify-content: center;
}

header.main nav .logout {
  padding-left: 4px;
  text-decoration: underline;
  cursor: pointer;
}

header.main nav > .user-header {
  font-weight: bold;
  color: #fff;
  font-size: 11px;
}

header.main nav > a {
  font-size: 15px;
  font-weight: lighter;
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
  margin-left: 10px;
}

.icono-registrar{
  width: 60%;
}

.icono-home{
  height: 40%;
  margin-left: 50px;
}

.lema-logo{
  flex: 1;
  font-size: 14px;
  font-weight: lighter;
  color: #fff;
  padding-left: 100px;
}

.rayita{
  width: 1px;
  height: 40px;
  border: solid 1px #979797;
}
