.producto {
  width: 192px;
  height: 220px;
  margin-right: 2%;
  margin-bottom: 2%;
  background-image: linear-gradient(-30deg, #e3f9ff, #f1f1f1);
  display: flex;
  justify-content: center;
}

.producto a {
  text-decoration: none;
}

.producto img {
  width: 100%;
  margin-top: 20%;
}

.producto h5 {
  font-weight: 100;
  font-size: 14px;
  color: #1c1c1b;
  margin-top: 15%;
  text-align: center;
}

.producto h4 {
  font-weight: bolder;
  font-size: 20px;
  color: #e15600;
  margin-top: 2%;
  text-align: center;
}
