.contenedor-register{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.register-logo {
  width: 100px;
  height: 100px;
}

.register-logo h4{
  text-align: center;
  margin-top: 10px;
  color: #1c1c1b;
}

.contenedor-campo-correo{
  width: 629px;
  height: 291px;
  margin-top: 60px;
  border-radius: 8px 8px 0 0;
  border: solid 1px #dcdcdc;
  background-color: #e15600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.email {
  width: 349px;
  height: 36px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Contrasena {
  margin-top: 25px;
  width: 349px;
  height: 36px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Confirmar-contrasena {
  margin-top: 25px;
  width: 349px;
  height: 36px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email input, .Contrasena input, .Confirmar-contrasena input {
  color: #7a7a7a;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
}

.continuar-boton{
  width: 629px;
  height: 50px;
  background-color: #1c1c1b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.continuar-boton button {
  color: #fee008;
  font-size: 16px;
  font-weight: lighter;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
}

.registrate-con{
  margin-top: 30px;
  font-size: 16px;
  font-weight: 100;
  color: #1c1c1b;
}

.contenedor-redes-ingreso{
  margin-top: 10px;
  width: 629px;
  height: 60px;
  border-radius: 8px;
  background-color: #303030;
  display: flex;
  justify-content: center;
  align-items: center;
}

.facebook, .google, .twitter, .instagram{
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.facebook img, .google img, .twitter img, .instagram img{
  width: 70%;
  height: 70%;
}
